<template>
  <!-- List all exsiting companies -->
  <items-table
    title="Companies"
    add-button-caption="New company"
    :add-function="showAddCompanyDialog"
    show-add-button
  >
    <company-list @edit-company="editItem"></company-list>
  </items-table>

  <!-- Dialog component for adding a new company record -->
  <base-dialog title="Add company" :open="showAddCompany">
    <template #actions>
      <a @click="closeAddCompanyDialog">X</a>
    </template>
    <template #default>
      <create-company @task-completed="closeAddCompanyDialog"></create-company>
    </template>
  </base-dialog>

  <!-- Dialog component for editing a company record -->
  <base-dialog title="Edit company" :open="showEditCompany">
    <template #actions>
      <a @click="closeEditCompanyDialog">X</a>
    </template>
    <template #default>
      <edit-company
        @task-completed="closeEditCompanyDialog"
        :company-id="editCompanyId"
      ></edit-company>
    </template>
  </base-dialog>
</template>

<script>
import { defineAsyncComponent, ref } from "vue";

const CreateCompany = defineAsyncComponent(() =>
  import("../../components/companies/CreateCompany.vue")
);
const EditCompany = defineAsyncComponent(() =>
  import("../../components/companies/EditCompany.vue")
);

import CompanyList from "../../components/companies/CompanyList.vue";

export default {
  components: {
    CreateCompany,
    CompanyList,
    EditCompany,
  },
  setup() {
    const showAddCompany = ref(false);
    const showEditCompany = ref(false);
    const editCompanyId = ref(null);

    /**
     * Functions is called if component CompanyList emits the event edit-company
     * Sets the selected company id and changes the flag showEditCompnay to true to show the eidt company dialog
     */
    function editItem(companyId) {
      editCompanyId.value = companyId;
      showEditCompany.value = true;
    }

    /**
     * Closes the edit company dialog by setting showEditCompany to false
     */
    function closeEditCompanyDialog() {
      showEditCompany.value = false;
      editCompanyId.value = null;
    }

    /**
     * Opens the add company dialog by setting howAddCompany to true
     */
    function showAddCompanyDialog() {
      showAddCompany.value = true;
    }

    /**
     * Closes the add company dialog by setting showAddCompany to false
     */
    function closeAddCompanyDialog() {
      showAddCompany.value = false;
      editCompanyId.value = null;
    }

    return {
      showAddCompany,
      showEditCompany,
      editCompanyId,
      editItem,
      closeEditCompanyDialog,
      closeAddCompanyDialog,
      showAddCompanyDialog,
    };
  },
};
</script>

<style scoped>
.toolbar {
  width: 100%;
  border: 1px solid #f38800;
  margin-bottom: 1em;
  padding: 0.75em;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.toolbar > button {
  margin-right: 10px;
}
</style>
